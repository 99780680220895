import styles from "./screen.module.scss";
import Lottie from "react-lottie";
import animationData from "@assets/Json/43_G.json";
import info from "@assets/SVG/info.svg";
import { Col, Image, Row } from "antd";
import DateTime from "./DateTime";
import Box from "@assets/SVG/Screen11_info.svg";
import AnimatedArrow from "@assets/SVG/Arrow_Gif.gif";
import GroupAmenities from "@assets/SVG/GroupAmenities_v2.svg";

export default function JSignBoard() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
}

export const Screen11 = () => {
  return (
    <div
      className="screen11"
      style={{
        background: styles.blue,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ width: "100%", background: styles.blue }}>
		<div style={{display : "flex", alignItems : 'center', justifyContent : "center", height : "62vh"}}>
      <div style={{width : "30%", display : "flex", justifyContent : "center"}}>
        <Image src={AnimatedArrow} preview={false} style={{height : "50vh"}}/>
			</div>
			{/* <div style={{width : "5%", height : "50vh"}}>
				<hr style={{ borderLeft: "0px solid transparent", width: "10px", height : "100%", display: "none" }} />
			</div> */}
			<div style={{width : "70%", display : "flex", justifyContent : "flex-start"}}>
				<Image src={Box} preview={false} style={{height : '60vh', width : "65vw"}} />
			</div>
		</div>
    <div style={{height : '1vh'}}>
      <hr style={{ border: "2px solid #fff", width: "100%" }} />
    </div>
		<div style={{ display: 'flex', justifyContent: 'center', alignItems:"center", height : "36vh" }}>
			<Image src={GroupAmenities} preview={false} style={{width : "94vw", maxHeight : "100%"}} />
		</div>
		{/* <div className="textVariantSectionSmall " style={{marginTop: "80px"}}>
			<DateTime />
		</div> */}
      </div>
    </div>
  );
};
